import _es from "../../modules/es6.symbol";
import _es6Object from "../../modules/es6.object.to-string";
import _es7Symbol from "../../modules/es7.symbol.async-iterator";
import _es7Symbol2 from "../../modules/es7.symbol.observable";
import _core from "../../modules/_core";
var exports = {};
_es;
_es6Object;
_es7Symbol;
_es7Symbol2;
exports = _core.Symbol;
export default exports;